import React from "react";
import { Teams } from "../Images/Images";
import Data from "../../Data/Data.json";

const Team = () => {
  return (
    <div className=" pt-5 pb-3">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h5 className="section-title ff-secondary text-center text-primary fw-normal">
            Members
          </h5>
          <h1 className="mb-5 cl-secondary">Partners and Directors</h1>
        </div>
        <div className="row g-4">
          {Data.Team.map((key,index) => {
            return (
              <div
                className="col-lg-4 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
                key={index}
                >
                <div className="team-item text-center rounded overflow-hidden">
                  <div className="rounded-circle overflow-hidden m-4">
                    <img className="img-fluid" src={Teams[index]} alt="" />
                  </div>
                  <h5 className="mb-0">{key.Name}</h5>
                  {/* <small>{key.Designation}</small><br/> */}
                 <a href={"mailto:"+key.Gmail}> <small>{key.Gmail}</small></a><br/>
                  <p className="team-desc">{key.Description}</p>
                  <div className="d-flex justify-content-center mt-3">
                    {/* <a
                      className="btn btn-square btn-primary mx-1"
                      href={key.Facebook}>
                      <i className="fab fa-facebook-f"></i>
                    </a>
                    <a
                      className="btn btn-square btn-primary mx-1"
                      href={key.Twitter}>
                      <i className="fab fa-twitter"></i>
                    </a> */}
                    {/* <a
                      className="btn btn-square btn-primary mx-1"
                      href={key.Gmail}>
                      <i className="fab fa-google"></i>
                    </a> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Team;
