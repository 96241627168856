import React from "react";
import Data from "../../Data/Data.json";
import { AccaImg, AccaLogoImg, HeroBackground } from "../Images/Images";

const Footer = () => {
  return (
    <div
      className="container-fluid footer-dark text-light footer wow fadeIn text-white"
      data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-4 col-md-6">
            <h4 className="section-title ff-secondary txt-dark text-start text-primary fw-normal mb-4">
              Company
            </h4>

            {Data.FooterLinks.map((key, index) => {
              return (
                <a
                  className="btn btn-link text-white"
                  key={index}
                  href={key.id}>
                  {key.Name}
                </a>
              );
            })}
          </div>
          <div className="col-lg-4 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 txt-dark">
              Contact
            </h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>
              {Data.Address}
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>
              {Data.Phone}
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>
              {Data.Email}
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-dark btn-social"
                href={Data.Twitter}>
                <i className="fab fa-twitter"></i>
              </a>
              <a
                className="btn btn-outline-dark btn-social"
                href={Data.Linkedin}>
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 txt-dark">
              Opening
            </h4>
            {Data.Timings.map((key, index) => {
              return (
                <span key={index}>
                  <p className=" fw-normal">{key.Days}<br/><span >{key.Time}</span></p>
                  
                </span>
              );
            })}
          </div> */}
          <div className="col-lg-3 col-md-6">
            <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4 txt-dark">
              {Data.Name}
            </h4>

            <div className="position-relative mx-auto d-flex justify-content-evenly">
              <img src={AccaLogoImg} width={"73px"} alt="logo" />
              <img src={AccaImg} width={"73px"} alt="logo" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0 d-flex align-items-center">
              &copy;&nbsp;
              <div>
                <a
                  className="border-bottom text-white"
                  href="http://dev.underwoodbarron.com/">
                  {Data.Name}
                </a>
                , All Right Reserved.
              </div>
              {/* <div className="d-flex ms-2">
              <a
                className="btn btn-outline-dark btn-social"
                href={Data.Twitter}>
                <i className="fab fa-twitter"></i>
              </a>
             
              <a
                className="btn btn-outline-dark btn-social"
                href={Data.Linkedin}>
                <i className="fab fa-linkedin"></i>
              </a>
            </div> */}
            </div>

            <div className="col-md-6 text-center text-md-end d-flex align-items-center justify-content-end">
              Managed By &nbsp;
              <a
                className="border-bottom text-white"
                href="https://www.dazzlingwebplanet.com">
                Dazzling Web Planet
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
