import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import BookDemo from "../Common/BookDemo";
import { Service } from "../Images/Images";
import Team from "../Team/team";

const Servv = (props) => {
    const path        = useLocation();
    const currentPath = !path.pathname.includes("services");
    const Services    = currentPath ? Data.Service.slice(0,3): Data.Service;
    
    return(<div className="py-5 mt-3">
    <div className="container">
        <div className="row g-5">
        
            <div className="col-lg-6">
            {/* {!path.pathname.includes("about") ?<h5 className="section-title ff-secondary text-start text-primary fw-normal">Services we offer</h5>: ""} */}
                <div className="row g-3">
                    <div>
                        <img className="img-fluid rounded w-75  wow zoomIn" alt="About" data-wow-delay="0.7s" src={Service}/>
                    </div>
                </div>
            </div>
            <div className={path.pathname.includes("services")? "col-lg-6" : "col-lg-6 text-center"}>
                
                <h1 className="mb-4 cl-secondary mt-0">Services We <span className="text-primary">Offer</span></h1>
                <ul>
                  {Services.map((Servv,index)=>{
                    return(<li style={{'fontFamily': 'sans-serif'}} key={index}>{Servv}</li>)
                  })}
                </ul>
            </div>
            {path.pathname.includes("about") &&  <Team/>}
        </div>
    </div>
</div>);
};

export default Servv;
