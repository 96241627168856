import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import BookDemo from "../Common/BookDemo";
import { Aboutus } from "../Images/Images";
import Team from "../Team/team";

const About = (props) => {
    const path = useLocation();
    
    return(<div className="py-5 mt-3">
    <div className="container">
        <div className="row g-5 align-items-center">
        
            {/* <div className="col-lg-6">
            {!path.pathname.includes("about") ?<h5 className="section-title ff-secondary text-start text-primary fw-normal">About Us</h5>: ""}
                <div className="row g-3">
                    <div>
                        <img className="img-fluid rounded w-100 wow zoomIn" alt="About" data-wow-delay="0.7s" src={Aboutus}/>
                    </div>
                </div>
            </div> */}
            <div className={path.pathname.includes("about")? "col-lg-12" : "col-lg-12 text-center"}>
                
                <h1 className="mb-4 cl-secondary">Welcome to <span className="text-primary">{Data.Name}</span></h1>
                {!path.pathname.includes("about") ? <>
                <p className="mb-4">{Data.About[0]}</p>
                <p className="mb-4">{Data.About[1]}</p></>
                :
                Data.About.map((key,index)=>{
                    return <p className="mb-4 subtext" key={index}>{key}</p>
                })} 
                
                {!path.pathname.includes("about") && <a className=" py-2 px-4 mt-2 ft-black btn btn-primary" href="/about">Read More</a>}    
            </div>
            {path.pathname.includes("about") &&  <Team/>}
        </div>
    </div>
</div>);
};

export default About;
