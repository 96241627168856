import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { useNavigate } from "react-router-dom";




export default function Cookie() {
const navigate = useNavigate();
    return (
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          enableDeclineButton
          flipButtons
          declineButtonText="Reject"
          cookieName="myWebsiteCookies"
          // onDecline={() => {}}
          style={{ background: "#fff", color:"#0F172B" }}
          buttonStyle={{ color: "#fff", fontSize: "13px", backgroundColor: "#0F172B" }}
          declineButtonStyle={{ color: "#fff", fontSize: "13px", backgroundColor: "#ca3538" }}
          expires={150}
        >
          This website uses cookies to ensure you get the best experience on our website.
          <button
      style={{
        color: 'gray',
        backgroundColor: 'transparent',
        border: "none"
      }}
      onClick={(e)=>{navigate("/Privacy")}}
    >
Privacy Policy.
    </button>

        </CookieConsent>
      </div>
    );
  }
  