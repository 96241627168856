import React from "react";
import Data from "../../Data/Data.json";

const Pricing = () => {
  return (
    <div className="container"> 
    <div className='col-lg-12'>
    <h1 className="mb-4 d-flex justify-content-center d-blk z-9">
      Simple <span className={`text-primary `}>&nbsp;Pricing</span>
    </h1>
  </div>
  <div class="innerContainer">
              <p class="paddingBottom45 industries f-center">
              Easy pricing means no suprises.
              </p>
              </div>
    <div class="row Banner text-center align-items-end">
      {Data.Pricing.map((Package, index) => {
        return (
          <div class="col-md-4 col-sm-12 mb-5 mb-lg-0 ">
            <div class="bg-white p-5 rounded-lg shadow mn-700">
              <h1 class="h6 font-weight-bold mb-2 primary fw-30">
                {Package.id}
              </h1>
              <ul class="list-unstyled my-5 text-small text-left font-weight-normal">
                {Package.Offers.map((service,index)=>{
                    return(
                    <li class="mb-3">
                    <i class="fa fa-check-circle mr-2 text-primary"></i>&nbsp; 
                    {service}
                  </li>)
                })}
              </ul>
              <h2 class="mb-4">
                {Package.Commission}
              </h2>

              <a href="/contact" class="btn btn-primary py-2 px-4 text-black">
                Tip Bits
              </a>
            </div>
          </div>
        );
      })}
    </div>
    </div>
  );
};
export default Pricing;
