import React from "react";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Team from "./Components/Team/team";
import Menu from "./Components/Menu/Menu";
import Privacy from "./Components/PolicynConditions/Privacy";
import Conditions from "./Components/PolicynConditions/Conditions";
import Cookie from "./Components/Cookies";
import Work from "./Components/Common/Work";
import Service from "./Components/Services/Service";
import Notfound from "./Components/NotFound/Notfound";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import Banner from "./Components/Banner/Banner";
import Pricing from "./Components/Common/Pricing";
import Serv from "./Components/Services";
import Services from "./Components/Common/Services";
import Servv from "./Components/Services/Services";

const IRoutes = () => {
  return (
    <Router>
      <Navbar />
      <div className="position-relative p-0 ">
        <Banner />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/team" element={<Team />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/products" element={<Work />} />
        <Route path="/services" element={<Servv/>} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Conditions />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/industries" element={<Services />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
      <Cookie />
    </Router>
  );
};

export default IRoutes;