import About from "../About/About";
import Contact from "../Contact/Contact";
import Services from "../Common/Services";
import Work from "../Common/Work";
import Service from "../Services/Service";
import ClientSlider from "../Common/ClientSlider";
import Comparsion from "../Common/Comparison";
import Team from "../Team/team";
import Serv from "../Services/index";
import Availability from "../Availability";
import BookDemo from "../Common/BookDemo";

const Home = () => {
  return (
    <>
      {/* <ClientSlider/> */}
      <About />
      <BookDemo/>
      {/* <Work/> */}
      {/* <Comparsion/> */}
      {/* <Service/> */}
      {/* <Contact /> */}
      {/* <Services/> */}
      {/* {<Team/>} */}
      {/* <Serv/> */}
      <Services/>
      <Availability/>
    </>
  );
};

export default Home;
