import React from "react";
import { useLocation } from "react-router-dom";
import Data from "../../Data/Data.json";
import { Industries } from "../Images/Images";
import "./Locations.css";

const Locations = (props) => {

  return (
    <div className="py-5 bg-grey mt-5">
      <div className="container">
        <div className='col-lg-12'>
          <h1 className="mb-4 d-flex justify-content-center d-blk z-9 cl-secondary">
          Where Are We <span className={`text-primary me-2`}>&nbsp;Available</span>
          </h1>
        </div>
                <div className="row text-center wrapper">
                  {Data.Locations.map((loc,index)=>{
                    return(  
                      <div className='my-2 col-lg-3 col-md-6 col-sm-6 col-xs-12' key={index}>
                      <div className="hover panel">
                        <div className="front">
                          <div className="box2">
                          <p className="fw-bold">{loc.place}</p>
                          <p>{loc.name}</p>
                          <p>{loc.email}</p>
                          <p>{loc.contact}</p>
                          </div>
                        </div>
                        {/* <div className="back">
                          <div className="box2">
                            <p>{loc.name}</p>
                            <p>{loc.contact}</p>
                          </div>
                        </div> */}
                      </div>
                  </div>
                    );
                  })}
              <p className=" mt-4 pt-2 fs-5 availability">
            Underwood Barron LLP is registered to carry on audit work in the UK and Ireland and regulated for a range of investment business activities by the The Institute of Chartered Accountants in England and Wales.
              </p>
            </div>
          
        <div className="d-flex justify-content-end mb-4">
        {/* {currentPath &&<a className="btn btn-primary py-2 px-4 mt-2 ft-black" href="/industries">Show More</a>} */}
            </div>
      </div>
    </div>

  );
};

export default Locations;

