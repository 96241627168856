import "./css/bootstrap-override.css";
import "./css/animate/animate.min.css";
import "./App.css";
import $ from "jquery";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footer from "./Components/Footer/Footer";
import { useEffect } from "react";
import IRoutes from "./Routes";
import SEO from "./Components/SEO/Seo";

function App() {
  useEffect(() => {
    $(document).ready(function(){
      
        $('.place-card').css('display','none');
      // set up hover panels
      // although this can be done without JavaScript, we've attached these events
      // because it causes the hover to be triggered when the element is tapped on a touch device
      $('.hover').hover(function(){
        $(this).addClass('flip');
      },function(){
        $(this).removeClass('flip');
      });
    });
    
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        $(".back-to-top").fadeIn("slow");
      } else {
        $(".back-to-top").fadeOut("slow");
      }
      if ($(this).scrollTop() > 45) {
        // $(".navbar").addClass("sticky-top shadow-sm");
      } else {
        // $(".navbar").removeClass("sticky-top shadow-sm");
      }
    });
  }, []);

  const handleOnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className=" bg-white p-0">
      <SEO/>
      <IRoutes/>
      <Footer />
      <button
        onClick={handleOnTop}
        className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="fa fa-arrow-up"></i>
      </button>
    </div>
  );
}

export default App;
