import Testimonial1   from "../../img/testimonial-1.jpg";
import Testimonial2   from "../../img/testimonial-2.jpg";
import Testimonial3   from "../../img/testimonial-3.jpg";
import Testimonial4   from "../../img/testimonial-4.jpg";
import HeroPng        from "../../img/bg-hero.svg";
import BGHero         from "../../img/bg-hero11.jpeg";
import About1         from "../../img/lionabout-1.jpeg";
import About2         from "../../img/lionabout-2.jpeg";
import About3         from "../../img/lionabout-3.jpeg";
import About4         from "../../img/lionabout-4.jpeg";
import Team1          from "../../img/team-1.jpg";
import Team2          from "../../img/team-2.jpg";
import Team3          from "../../img/team-3.jpg";
import Team4          from "../../img/team-4.jpg";
import Menu           from "../../img/lionmenu.png";
import About          from "../../img/about.jpg";
import AccaLogo       from "../../img/accalogo.png";
import Acca           from "../../img/acca.png";

//Icons
import Customer       from "../../img/customer.png";
import Pickup         from "../../img/pickup.png";
import Order          from "../../img/order.png";
import Mobile         from "../../img/mobhand.png";
import Marketing      from "../../img/marketing.png";
import Website        from "../../img/website.png";
import Delivery       from "../../img/delivery.png";
import Retail         from "../../img/retail.png";
import Art            from "../../img/art.png";
import Cafe           from "../../img/cafe.png";
import Food           from "../../img/food.png";
import Hospitality    from "../../img/hospitality.png";
import Media          from "../../img/media.png";
import Takeaway       from "../../img/takeaway.png";

//Features
import WebApp         from "../../img/WebApp.png";
import App            from "../../img/App.png";
import Market         from "../../img/Market.png";
import AllFeatures    from "../../img/Feature.gif";

//Contact
import Contact        from "../../img/Contact.png";

//Services
import Services       from "../../img/Services.png";

//Clients
import GrilletWebp    from "../../img/grillet.webp";
import KaliPng        from "../../img/kali.png";
import DunningsWebp   from "../../img/dunnings.webp";


import Agricultural from "../../img/Agricultural.jpg";
import Building from "../../img/Building.jpg";
import Engineering from "../../img/Engineering.jpg";
import Farmers from "../../img/Farmers.jpg";
import Finance from "../../img/Finance.jpg";
import Franchises from "../../img/Franchises.jpg";
import Landlords from "../../img/Landlords.jpg";
import Manufacturing from "../../img/Manufacturing.jpg";
import Marine from "../../img/Marine.jpg";
import Professionals from "../../img/Professionals.jpg";
import Pubs from "../../img/Pubs.jpg";
import Parks from "../../img/Parks.jpg";
import RetailI from "../../img/Retail.jpg";
import IndustriesI from "../../img/Industries.jpg";
import Software from "../../img/Software.jpg";
import Manymore from "../../img/Manymore.jpg";



//Services
import Service1  from "../../img/Service1.jpg";
import Service2  from "../../img/Service2.jpg";
import Service3  from "../../img/Service3.jpg";
import Service4  from "../../img/Service4.jpg";
import Service5  from "../../img/Service5.jpg";
import Service6  from "../../img/Service6.jpg";
import Service7  from "../../img/Service7.jpg";
import Service8  from "../../img/Service8.jpg";
import Service9  from "../../img/Service9.jpg";
import Service10 from "../../img/Service10.jpg";
import Service11 from "../../img/Service11.jpg";
import Service12 from "../../img/Service13.jpg";
import Service13 from "../../img/Service13.jpg";
import Service14 from "../../img/Service14.jpg";
import Service15 from "../../img/Service15.jpg";
import Service16 from "../../img/Service16.jpg";
import Service17 from "../../img/Service17.jpg";
import Service18 from "../../img/Service18.jpg";
import Service19 from "../../img/Service19.jpg";
import Service20 from "../../img/Service20.jpg";
import Service21 from "../../img/Service21.jpg";



//Exports"Tax investigations",


export const Aboutus        = About;
export const MenuImg        = Menu;
export const AccaLogoImg    = AccaLogo;
export const AccaImg        = Acca;
export const Abouts         = [About2, About3, About4, About2];
export const Testimonials   = [Testimonial1, Testimonial2, Testimonial3, Testimonial4];
export const Teams          = [Team1, Team2, Team3, Team4, Team4];
export const HeroBackground = HeroPng;
export const Icons          = [Order, Takeaway, Pickup, Mobile];
export const Features       = [WebApp, App, Market];
export const FeaturesWrap   = AllFeatures;
export const ContactUs      = Contact;
export const Industries     = [Agricultural, Building, Engineering, Farmers, Finance, Franchises, Landlords, Manufacturing, Marine, Professionals, Pubs, Parks, RetailI, IndustriesI, Software, Manymore];
export const ServiceBanner  = Services;
export const BgHero         = BGHero;
export const ServicesI      = [Service4, Service5, Service10, Service20, Service9, Service19, Service7, Service12,Service17, Service1, Service2, Service3, Service6, Service8, Service11, Service13, Service14, Service15, Service16, Service18, Service21];
export const Service        = Service6;
export const Clients        = {
   "KaliMirchi" : {
        "Webp" : "",
        "Png"  : KaliPng
    },
    "Grillet" :
    {
        "Webp" : GrilletWebp,
        "Png"  : ""
    },
    "Dunnings" :
    {
        "Webp" : DunningsWebp,
        "Png"  : ""  
    }
};

